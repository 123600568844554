<template>
    <div class="start-show">
        <div class="start-show-content">
            <img v-if="$store.getters.project.project_logo" :src="$store.getters.project.project_logo" alt="">
            <div class="start-button" @click="this.$parent.startShow">
                START
            </div>
        </div>
    </div>
</template>

<script>
  import constants from '../../Constants';
  import {precacheAndRoute} from 'workbox-precaching';
  import { register } from 'register-service-worker'
  export default {
    name: 'show-home',
    data: ()=>({
      logo: '',
    }),
    mounted() {

    }
  }
</script>
