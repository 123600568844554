<template>
  <div class="unit-content">
    <div class="unit-info superscroll" v-show="openedUnitGallery == false"
         :class="{'with-photos': unit.photos.length > 0}">
      <div class="unit-back" @click="callback"></div>
      <div class="unit-common-info">
        <div class="common-block">
          <div class="unit-header">{{ project.name }}</div>
          <div class="unit-number" style="padding-bottom: 10px;" v-if="floor && project.house_type == 1"> Plan {{floor }}
          </div>
          <div class="unit-number">{{ project.house_type == 2 ? 'Home ' : 'Unit  '}} number: {{ unit.unit_number }}</div>
        </div>
        <div class="common-block">
          <div class="unit-extra-info" v-if="project.show_price == 1">$ {{ Intl.NumberFormat('en-US').format(unit.price) }}</div>
          <div class="unit-bad-bath">
            <div class="unit-extra-info right-delimiter" v-if="unit.bad != 0">{{ unit.bad }} Bed</div>
            <div class="unit-extra-info" v-if="unit.bad != 0">{{ unit.bath }} Bath</div>
            <div class="unit-extra-info" v-if="unit.bad == 0">STUDIO</div>
          </div>
        </div>

        <div class="common-block" style="line-height: 20px;">
          <div class="unit-extra-info" v-html="unit.commentary"></div>
          <div class="unit-extra-info" style="">
            Interior: {{ interior_text }} Sq.Ft.
          </div>
          <div class="unit-extra-info" v-if="unit.ext_sq != '' && unit.ext_sq != null">
            Exterior: {{ exterior_text }} Sq.Ft.
          </div>
          <div class="unit-extra-info">
            HOA: ${{ hoa_text }}
          </div>
          <div class="unit-extra-info" style="" v-if="unit.parking != 0">
            Parking
          </div>
          <div class="unit-extra-info" style="" v-if="unit.bmr != 0">
            BMR
          </div>
          <div class="unit-extra-info" style="" v-if="unit.den != 0">
            Den
          </div>
          <div class="unit-extra-info" style="" v-if="unit.loft != 0">
            Loft
          </div>
          <div class="unit-extra-info" style="" v-if="unit.terrace != 0">
            Terrace
          </div>
          <div class="unit-extra-info" style="" v-if="unit.outdoor_areas != 0">
            Outdoor areas
          </div>
        </div>
        <div class="common-block">
          <div class="unit-extra-info">{{ statusText(Number.parseInt(unit.status)) }}</div>
          <div class="unit-extra-info" v-if="unit.status == 1">Available since: {{makeDate(unit.available_since)}}</div>
        </div>
      </div>
      <div class="unit-gallery" v-if="unit.photos.length > 0">
        <div class="carousel">
          <div class="carousel-images" :style="{left: '-' + activePhoto * 300 + 'px'}">
            <img :src="image.image_link" alt="" v-for="image in unit.photos"
                 @click="openUnitGallery">
          </div>
        </div>
        <div class="carousel-dots">
          <div class="carousel-dots__container">
            <div class="carousel-dot" :class="{'active': activePhoto === key -1}"
                 v-for="(key, dot) in unit.photos.length" @click="activePhoto = key - 1"
                 v-if="key <= 10"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="image-container">
<!--      <div class="image-toggle-container">
        <div class="toggle-text">Unit plan</div>
        <div class="image-toggle" @click="modelToggle">
          <div class="toggle-circle" :class="{active: model}"></div>
        </div>
        <div class="toggle-text">3D model</div>
      </div>-->
      <div>
        <div class="unit-image" v-show="!model">
          <div v-show="unit.image != undefined">
            <img :src="unit.image" alt="" @click="openImage(unit.image)">
          </div>
        </div>

        <div v-if="isModalOpen" class="modal" @click="closeModal">
          <span class="close" @click="closeModal">&times;</span>
          <img class="modal-content" :src="currentImage">
        </div>
      </div>
      <div class="unit-image iframe-container" v-if="model">
        <iframe class="unit-iframe" src="https://maggio-sources.com" frameborder="0"></iframe>
      </div>
    </div>
    <Gallery v-show="unit.photos.length > 0 && openedUnitGallery == true"
             :images="unit.photos.map( image => image.image_link)"
             :backButtonClick="closeUnitGallery"></Gallery>
  </div>
</template>

<script>
import Gallery from '../app/Gallery.vue'

export default {
  'name': 'OpenedUnitShow',
  components: {
    Gallery
  },
  data() {
    return {
      isModalOpen: false,
      currentImage: '',
      model: false,
      statuses: ['Available', 'Reserved', 'Sold', 'Future'],
      finishes_schemes: ['Light and Airy', 'Formal', 'Chick', 'BMR'],
      openedUnitGallery: false,
      activePhoto: 0,
    }
  },
  props: ['unit', 'callback', 'project', 'floor'],
  mounted() {

  },
  beforeMount() {

  },
  beforeDestroy() {

  },
  computed: {
    interior_text() {
      return new Intl.NumberFormat('en-US').format(this.unit.int_sq);
    },
    exterior_text() {
      return new Intl.NumberFormat('en-US').format(this.unit.ext_sq);
    },
    hoa_text() {
      return new Intl.NumberFormat('en-US').format(this.unit.hoa);
    }
  },
  methods: {
    openImage(image) {
      this.currentImage = image;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.currentImage = '';
    },
    makeDate(unix) {
      let date = new Date();
      if(unix !== '') {
        date = new Date(Number.parseInt(unix));
      }
      return date.getFullYear() + '-' + ((date.getMonth() + 1) >= 10? (date.getMonth() + 1): '0' + (date.getMonth() + 1)) + '-' +
        (date.getDate() >= 10? date.getDate(): '0' + date.getDate());
    },
    statusText(unit) {
      switch (unit) {
        case 0:
          if (this.project.sale == 0) {
            return 'Available for rent'
          } else {
            return 'Available for sale'
          }
        case 1:
          if (this.project.sale == 0) {
            return 'Rented out'
          } else {
            return 'Reserved'
          }
        case 2:
          if (this.project.sale == 0) {
            return 'Rented out'
          } else {
            return 'Sold'
          }
        case 3:
          if (this.project.sale == 0) {
            return 'Future'
          } else {
            return 'Future'
          }
      }
    },
    modelToggle() {
      this.model = !this.model;
    },
    carouselGoLeft(e) {
      if (this.activePhoto == 0) {
        return false;
      } else {
        this.activePhoto--;
      }
    },
    carouselGoRight(e) {
      if (this.activePhoto == this.unit.photos.length - 1) {
        return false;
      } else {
        this.activePhoto++;
      }
    },
    openUnitGallery() {
      this.openedUnitGallery = true;
    },
    closeUnitGallery() {
      this.openedUnitGallery = false;
    },
  }
}
</script>
<style scoped>
.textarea {
  width: 100%;
  border-radius: 8px;
  background: #606264;
  padding: 10px;
  color: white;
  border: 1px solid rgba(54, 60, 64, 0.5);
}
.bold {
  font-weight: 600;
}

.toggle-text {
  padding-left: 5px;
  padding-right: 5px;
}

.image-toggle-container {
  display: flex;
  height: 20px;
  padding-left: 60px;
  padding-top: 20px;
}

.toggle-circle.active {
  left: calc(100% - 18px);
  background-color: white;
}

.toggle-circle {
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: all 0.3s ease;
}

.image-toggle {
  position: relative;
  background-color: #a4a4a4;
  width: 50px;
  height: 20px;
  border-radius: 10px;
}

.image-toggle-container {
  cursor: pointer;
}

.image-container {
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
}
.unit-image {
  max-width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.unit-image img {
  cursor: pointer;
  transition: transform 0.2s;
}

.unit-image img:hover {
  transform: scale(1.1);
}

.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

.modal-content {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from { -webkit-transform: scale(0) }
  to { -webkit-transform: scale(1) }
}

@keyframes zoom {
  from { transform: scale(0) }
  to { transform: scale(1) }
}
</style>
