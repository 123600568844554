<template>
    <div class="units-list superscroll">
        <div class="units-list-blocks">
            <div class="units-list-header">{{$store.getters.project.house_type == 1 ? 'Unit Number' : 'House Number'}}</div>
            <div class="units-list-header">Status</div>
            <div class="units-list-header" v-if="$store.getters.project.show_price == 1">Price <div v-if="$store.getters.project.sale == 0">{{'(Monthly)'}}</div></div>
            <div class="units-list-header" v-if="$store.getters.project.sale == 0">Refundable Deposit</div>
            <div class="units-list-header" v-if="$store.getters.project.house_type == 1">Floor</div>
            <div class="units-list-header">Sq.ft (int.)</div>
            <div class="units-list-header">Sq.ft (ext.)</div>
            <div class="units-list-header">Bed</div>
            <div class="units-list-header">Bath</div>
            <div class="units-list-header show-bmr">BMR</div>
            <div class="units-list-header"></div>
        </div>
        <div class="" v-for="(floor, index) in this.$store.getters.project.floors">
            <div class="units-list-blocks" v-for="(unit, index2) in floor.units">
                <div class="units-list-value">{{unit.unit_number}}</div>
                <div class="units-list-value" :class="'status-' + unit.status" :title="statusTitle(unit)">{{createStatus(unit)}}
                  <div class="unit-available-since" style="font-size: 10px" v-if="unit.status == 1">{{statusTitle(unit)}}</div></div>
                <div class="units-list-value" v-if="$store.getters.project.show_price == 1">$ {{unit.price}}</div>
                <div class="units-list-value" v-if="$store.getters.project.sale == 0">$ {{unit.deposit != null?unit.deposit : '-'}}</div>
                <div class="units-list-value" v-if="$store.getters.project.house_type == 1">{{index+ 1}}</div>
                <div class="units-list-value">{{unit.int_sq}}</div>
                <div class="units-list-value">{{unit.ext_sq}}</div>
                <div class="units-list-value">{{unit.bad}}</div>
                <div class="units-list-value">{{unit.bath}}</div>
                <div class="units-list-value show-bmr"><span v-if="unit.bmr == 1">Yes</span><span v-else>No</span></div>
                <div class="units-list-value units-icons">
                    <div class="show-button" @click="openUnit(unit, floor.number, index + '_' +index2)">Show</div>
                </div>
              <OpenedUnitShow v-show="openUnitPage === index + '_' +index2"
                              :unit="unit"
                              :floor="activeFloor"
                              :callback="closeUnitShow"
                              :project="$store.getters.project"></OpenedUnitShow>
            </div>
        </div>
    </div>
</template>

<script>
  import constants from '../../Constants';
  import OpenedUnitShow from '../project/OpenedUnitShow.vue'
  export default {
    components: {OpenedUnitShow},
    name: 'Units',
    data: ()=>({
      statusNames: constants.STATUS_OPTIONS,
      openedUnit: {},
      openUnitPage: '',
      activeFloor: '',
    }),
    mounted() {

    },
    methods: {
      createStatus(unit) {
        if(unit.status == 2 && this.$store.getters.project.sale == 0) {
          unit.status = 3;
          return this.statusNames[unit.status].name;
        } else {
          return this.statusNames[unit.status].name;
        }
      },
      createDate(unit) {
        if(unit.status == 1) {
          let string = unit.available_since;
          let date = new Date(Number.parseInt(string));
          return date.getFullYear() + '-' +
            ((date.getMonth() + 1) > 10 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1) + '-' +
              (date.getDate() > 10 ? date.getDate() : '0' + date.getDate()));
        } else {
          return '';
        }
      },
      statusTitle(unit) {
        if(unit.status == 1 && unit.available_since != '') {
          return  'Available since: ' + this.makeDate(unit.available_since)
        } else {
          return '';
        }
      },
      makeDate(unix) {
        let date = new Date(Number.parseInt(unix));
        return date.getFullYear() + '-' + ((date.getMonth() + 1) > 10? (date.getMonth() + 1): '0' + (date.getMonth() + 1)) + '-' +
          (date.getDate() > 10? date.getDate(): '0' + date.getDate());
      },
      openUnit: function (unit, floor, index) {
        this.activeFloor = floor;
        this.openedUnit = unit;
        this.openUnitPage = index;
      },
      closeUnitShow: function () {
        this.openUnitPage = false;
      }
    }
  }
</script>
<style scoped>
.units-list-header {
  flex-wrap: wrap;
  text-align: center;
}
.status-0 {
  color: rgba(1, 1, 189, 1);
}
.status-1 {
  color: rgb(168, 141, 6);
}
.status-2 {
  color: rgb(240 0 0 / 60%);
}
</style>
