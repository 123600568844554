<template>
    <div class="floor-container">
        <div class="show-floors">
            <div class="floor-image">
                <div class="units-list" v-if="floorImage == ''">
                    <div class="no-units" v-if="this.floor != '' && this.floor.units.length === 0">No units</div>
                    <div class="units-item" v-for="unit in this.floor.units" @click="openUnit(unit)">Unit number
                        {{unit.unit_number}}
                    </div>
                </div>
                <div class="image-container" v-if="floorImage != ''">
                    <img @load="toggleMarks" :src="temp.image" v-show="temp.image == floor.image" v-for="temp in $store.getters.project.floors"
                         :id="'floor-image' + temp.id" alt="">
                  <OpenedUnitShow v-for="(unit, key) in floor.units"
                                  v-show="openUnitPage === unit.id"
                                  :floor="floor.number"
                                  :unit="unit"
                                  :callback="closeUnitShow"
                                  :project="$store.getters.project" />
                  <template v-if="showMarks">
                    <div :class="{'visible': isMounted, 'hidden': !isMounted}" style="cursor: pointer; transition: all .3s" @click="openUnitPage = unit.id"  class=""
                         v-for="(unit) in filteredUnits" :key="unit.id">
                      <unitMark :type="'show'" :item="unit" />
                    </div>
                  </template>
                </div>
            </div>
            <div class="navigation-container" v-if="$store.getters.project.floors.length > 1">
                <div class="navigation-block">
                    <div class="floor-navigation superscroll">
                        <div class="floor-navigation-tab" @click="openFloor($store.getters.project.floors.length - index - 1)"
                             :class="{active: $store.getters.project.floors.length - index - 1 === activeFloor}"
                             v-for="(floor, index) in this.$store.getters.project.floors">
                            {{$store.getters.project.floors.length - index}}
                        </div>
                    </div>
                </div>
              <div class="floors-nav">
                <div class="floor-button floor-up pointer" v-if="$store.getters.project.floors.length > 8" @click="floorNavUp"></div>
                <div class="floors-container" v-if="$store.getters.project.floors.length > 1">
                  <div class="navigation-list" :style="{'margin-bottom': marginFloor + 'px'}">
                    <div class="floor-item" @click="openFloor($store.getters.project.floors.length - index - 1)"
                         :class="{active: $store.getters.project.floors.length - index - 1 === activeFloor}"
                         v-for="(floor, index) in this.$store.getters.project.floors">
                      {{$store.getters.project.floors.length - index}}
                    </div>
                  </div>
                </div>
                <div class="floor-button floor-down pointer" v-if="$store.getters.project.floors.length > 8" @click="floorNavDown"></div>
              </div>
            </div>
        </div>
        <div class="show-floors-footer">
          <div class="filter-container">
<!--            <div class="filter-header">Unit marks: </div>-->
            <select class="show-select" aria-placeholder="Select status" v-model="showMarks">
              <option :value="true">Inventory</option>
              <option :value="false">
                Hide
              </option>
            </select>
          </div>
          <div class="filter-container">
<!--            <div class="filter-header">TYPE: </div>-->
            <select class="show-select" aria-placeholder="Select status" v-model="filters.bmr">
              <option :value="false">
                Market Rate
              </option>
              <option :value="true">BMR</option>
            </select>
          </div>
<!--          <div class="unit-show">
           <div class="unit-show-text" @click="filters.bmr = !filters.bmr" :class="{active: filters.bmr}">
             BMR
           </div>
          </div>-->
          <div class="filter-container" v-if="bedroomList.length > 1">
            <div class="filter-header">Bedroom: </div>
            <select class="show-select" v-model="filters.bed">
              <option :value="false">
                All
              </option>
              <option v-for="(bedroom, key) in bedroomList" :value="bedroom.bed">
                {{ bedroom.bed === 0 ? 'STUDIO' : bedroom.bed + ' bedroom' }}
              </option>
            </select>
          </div>
<!--            <div class="bedrooms-list"
                 style="display: flex; align-items: center; justify-content: center;">
                <div class="status-list lot-info" v-show="$store.getters.project.house_type == 2">
                    <div class="status-item" @click="openUnit($store.getters.project.lot_info)">
                        <div class="status-item-title">
                            Site Plan
                        </div>
                    </div>
                </div>
              <select class="show-select" v-model="selectedBedroom" @change="makeBedFilter">
                <option v-for="(bedroom, key) in bedroomList" :value="bedroom.bed">
                  {{ bedroom.bed === 0 ? 'STUDIO' : bedroom.bed + ' bedroom' }}
                </option>
              </select>
            </div>-->
          <div class="filter-container">
            <div class="filter-header">Price: </div>
            <select class="show-select" aria-placeholder="Select status" v-model="filters.price">
              <option :value="false">
                All
              </option>
              <option v-for="price in prices" :value="price.id" :key="price.id">
                {{ price.text }}
              </option>
            </select>
          </div>
          <div class="filter-container">
            <div class="filter-header">Status: </div>
            <select class="show-select" aria-placeholder="Select status" v-model="selectedStatus" @change="makeStatusFilter">
              <option :value="false">
                All
              </option>
              <option v-for="(status, key) in statuses" :value="key">
                {{ status.text }}
              </option>
            </select>
          </div>

        </div>
    </div>
</template>

<script>
  import constants from '../../Constants';
  import OpenedUnitShow from '@/components/project/OpenedUnitShow.vue'
  import unitMark from "../../widgets/simple_components/unitMark";
  export default {
    components: {
      OpenedUnitShow, unitMark
    },
    name: 'show-floors',
    data: ()=>({
      lastFloor: 0,
      activeFloor: 0,
      activeFloorNav: 8,
      floor: { units: [{ bedShow: true, statusShow: true, unit_mark: { natural_width: 100, natural_height: 50 } }] },
      reserveFloor: '',
      showMarks: false,
      opacityMarks: false,
      floorImage: '',
      selectedBedroom:'',
      selectedStatus: false,
      bedroomList: [],
      statuses: [{id: '0', text: 'Available'}, {id: '1', text: 'Reserved'}, {id: '2', text: 'Sold'}, {id: '3', text: 'Future'} ],
      prices: [
        {id: 's', text: 'Less 800K'},
        {id: 'm', text: '800K - 900K'},
        {id: 'l', text: '900K - 1M'},
        {id: 'xl', text: 'More than 1M'}
      ],
      statusList: [],
      openedUnit: '',
      activePhoto: 0,
      openedUnitGallery: false,
      galleryActivePhoto: 0,
      unitGalleryOpened: false,
      openUnitPage: false,
      filters: {
        bed: false,
        status: false,
        bmr: false,
        price: false,
      },
      activeFilters: {
        bed: [],
        status: [],
      },
    }),
    props: {
      isMounted: {
        type: Boolean,
        default() {
          return false;
        }
      }
    },
    computed: {
      filteredUnits() {
        return this.floor.units.filter(unit => {
          // Фильтрация по BMR
          if (this.filters.bmr && unit.bmr === '0') {
            return false;
          }

          // Фильтрация по bedroom (количеству комнат)
          if (this.filters.bed && this.filters.bed != unit.bad) {
            return false;
          }
          if(this.filters.price) {
            const price = Number.parseInt(unit.price);
            switch (this.filters.price) {
              case 's':
                if(price >= 800000) {
                  return false;
                }
                break;
                case 'm':
                  if(price < 800000 || price > 900000) {
                    return false;
                  }
                  break;
              case 'l':
                if(price < 900000 || price > 1000000) {
                  return false;
                }
                break;
              case 'xl':
                if(price < 1000000) {
                  return false;
                }
                break;
            }
          }

          // Фильтрация по статусу
          if(this.filters.status !== false ) {
            if (this.filters.status != unit.status) {
              return false;
            }
          }


          return true;
        });
      },
      marginFloor() {
        return -(this.activeFloorNav - 8) * 46;
      },
    },
    mounted(){
      if(this.$store.getters.project.floors.length > 8) {
        this.lastFloor = 8;
      } else {
        this.lastFloor = this.$store.getters.project.floors.length;
      }
      this.floor = this.$store.getters.project.floors[0];
      if (this.$store.getters.project.floors[0].image != '' && this.$store.getters.project.floors[0].image != null) {
        this.floorImage = this.$store.getters.project.floors[0].image;
      }
      for (let i = 0; i < this.floor.units.length; i++) {
        let flag = true;
        this.floor.units[i].visible = true;
        for (let n = 0; n < this.bedroomList.length; n++) {
          if (this.bedroomList[n].bed === this.floor.units[i].bad) {
            flag = false;
          }
        }
        if (flag) {
          this.bedroomList.push({ bed: this.floor.units[i].bad });
          this.activeFilters.bed.push(this.floor.units[i].bad);
        }
        this.statusList[this.floor.units[i].status] = true;
      }
    },
    methods: {
      toggleMarks() {
        setTimeout(() => {
          this.showMarks = true;
          setTimeout(() => {
            this.opacityMarks = true;
          }, 300)
        }, 1000)
      },
      floorNavUp() {
        if(this.activeFloorNav >= this.$store.getters.project.floors.length) {
          return;
        }
        this.activeFloorNav++;
      },
      floorNavDown() {
        if(this.activeFloorNav <= 8) {
          return;
        }
        this.activeFloorNav--;
      },
      closeUnitShow() {
        this.openUnitPage = false;
      },
      openUnitGallery() {
        this.openedUnitGallery = true;
      },
      closeUnitGallery() {
        this.openedUnitGallery = false;
      },
      carouselGoLeft(e) {
        if(this.activePhoto == 0) {
          return false;
        } else {
          this.activePhoto--;
        }
      },
      carouselGoRight(e) {
        if(this.activePhoto == this.openedUnit.photos.length - 1) {
          return false;
        } else {
          this.activePhoto++;
        }
      },
      calculateFontSize(unit){
        let fontSize = unit.unit_mark.natural_height / 3 / 2;
        let fontSize2 = unit.unit_mark.natural_width / 8;
        if (fontSize < fontSize2) {
          var min = fontSize;
        } else {
          var min = fontSize2;
        }
        if (min > 5) {
          return Number.parseInt(min);
        } else {
          return 5;
        }
      },
      openFloor(index) {
        this.activeFloor = index;
        this.floor = this.$store.getters.project.floors[index];
        this.reserveFloor = this.$store.getters.project.floors[index];
        if (this.$store.getters.project.floors[index].image != '' && this.$store.getters.project.floors[index].image != null) {
          this.floorImage = this.$store.getters.project.floors[index].image;
        } else {
          this.floorImage = '';
        }
        if (this.$store.getters.project.house_type == 1) {
          this.renderFilters();
        }
        this.actualizeMarkers();
      },
      renderFilters(){
        this.bedroomList = [];
        this.statusList = [];
        for (let i = 0; i < this.floor.units.length; i++) {
          if (this.floor.units[i].bad != null) {
            let flag = true;
            for (let n = 0; n < this.bedroomList.length; n++) {
              if (this.bedroomList[n].bed === this.floor.units[i].bad) {
                flag = false;
              }
            }
            if (flag) {
              this.bedroomList.push({ bed: this.floor.units[i].bad });
            }
          }
          this.statusList[i] = false;
          this.statusList[this.floor.units[i].status] = true;
        }
      },
     /* makeBedFilter() {
        console.log(this.selectedBedroom);
        if(this.selectedBedroom !== null) {
          this.filters.bed = [this.selectedBedroom];
        } else {
          this.filters.bed = [];
        }
        this.useFilter();
      },*/

      makeStatusFilter() {
        if(this.selectedStatus !== null) {
          this.filters.status = this.selectedStatus;
        } else {
          this.filters.status = false;
        }

      },
      useFilter() {
        for (var iter = 0; iter < this.floor.units.length; iter++) {
          this.floor.units[iter].bedShow = true;
          this.floor.units[iter].statusShow = true;
        }
        for (var iter = 0; iter < this.floor.units.length; iter++) {
          for (var n = 0; n < this.filters.bed.length; n++) {
            if (this.floor.units[iter].bad == this.filters.bed[n]) {
              this.floor.units[iter].bedShow = false;
            }
          }
        }
        for (let iter2 = 0; iter2 < this.floor.units.length; iter2++) {
          for (let m = 0; m < this.filters.status.length; m++) {
            if (this.floor.units[iter2].status == this.filters.status[m]) {
              this.floor.units[iter2].statusShow = false;
            }
          }
        }
      },
      openUnit(unit){
        this.openedUnit = unit;
        this.openUnitPage = true;
      },
      closeUnitPage(){
        this.openedUnit = false;
        this.openUnitPage = false;
      }
    }
  }
</script>
<style lang="less" scoped>
.hidden {
  opacity: 0;
}
.visible {
  opacity: 1;
}
.filter-header {
  font-size: 18px;
  margin-right: 10px;
}
.filter-container {
  display: flex;
  align-items: center;
}
.show-select {
  height: 40px;
  border: 1px solid rgba(118, 118, 118, 0.65);
  border-radius: 6px;
  width: 120px;
}
.filters-text {
  font-size: 18px;
  height: 100%;
  display: flex;
  color: #767676;
  align-items: center;
  padding-left: 20px;
  font-weight: bold;
}
.unit-show {
  height: 100%;
  display: flex;
  align-items: center;
}
.bedrooms-list {
  height: 100%;
  align-items: center;
}
.unit-show-text.active {
  font-family: RobotoBold, sans-serif;
  color: rgba(0, 0, 255, 0.5);
}
.show-project .show-floors-footer input[type="checkbox"]:checked + label {
  color: rgba(0, 0, 255, 0.5);
}
.unit-show-text {
  white-space: nowrap;
  margin-left: 100px;
  margin-right: 10px;
  color: #767676;
  cursor: pointer;
}
.show-project .show-floors .floor-image  .unit-point {
  color: white;
}
.show-project .show-floors .floor-image  .unit-point.status-3 {
  border: 1px solid rgba(40, 40, 49, 0.2);
}
.show-project .show-floors .floor-image  .unit-point.status-0 {
  border: 1px solid rgba(0, 0, 255, 0.2);
}
.show-project .show-floors .floor-image  .unit-point.status-1 {
  border: 1px solid rgba(206, 172, 0, 0.2);
}
.show-project .show-floors .floor-image  .unit-point.status-2 {
  border: 1px solid rgba(240, 0, 0, 0.2);
}
.show-project .show-floors .floor-image  .unit-point.status-2 .unit-point-bedrooms,
.show-project .show-floors .floor-image .unit-point .unit-point-status.status_2,
.show-project .show-floors .floor-image .unit-point.status-2 .unit-point-number {
  background-color: rgba(240, 0, 0, 0.5);
}
.show-project .show-floors .floor-image  .unit-point.status-1 .unit-point-bedrooms,
.show-project .show-floors .floor-image .unit-point .unit-point-status.status_1,
.show-project .show-floors .floor-image .unit-point.status-1 .unit-point-number {
  color: rgba(206, 172, 0, 0.5);
}
.show-project .show-floors .floor-image  .unit-point.status-0 .unit-point-bedrooms,
.show-project .show-floors .floor-image .unit-point .unit-point-status.status_0,
.show-project .show-floors .floor-image .unit-point.status-0 .unit-point-number {
  color: rgba(0, 0, 255, 0.5);
}
.show-project .show-floors .floor-image  .unit-point.status-3 .unit-point-bedrooms,
.show-project .show-floors .floor-image .unit-point .unit-point-status.status_3,
.show-project .show-floors .floor-image .unit-point.status-3 .unit-point-number {
  color: rgba(40, 40, 49, 0.5);
}
.floor-container {
  position: relative;
}
.show-floors-footer {
  top: 0;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>
