<template>
    <div class="show-project" :class="{no__padding: this.$parent.activeTab == 'home'}">
        <div class="app-menu-button"  v-if="this.$parent.activeTab !== 'home'">
            <div class="menu-button" @click="openBrownMenu">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="menu-header">{{$store.getters.project.name}}</div>
        </div>
        <div class="app-head" v-if="this.$parent.activeTab != 'home'">
            <div class="app-open-menu">
                <div class="menu-icon"></div>
                <div class="project-name">{{$store.getters.project.name}}</div>
            </div>
            <div class="app-head-name"><!--{{$store.getters.project.name}}--></div>
            <div class="app-head-tab" v-if="$store.getters.project.house_type == 2 && this.$parent.activeTab == 'Floor plates'">
               Site Plan</div>
          <div class="app-head-tab" v-else-if="$store.getters.project.house_type == 2 && this.$parent.activeTab == 'Units'">
            Houses</div>
            <div class="app-head-tab" v-else>
              Site Plan
            </div>
          <div class="update-container" style="justify-content: flex-end" data-id="update-container" v-if="$store.getters.isOnline">
            <div class="dots-icon" v-if="$store.getters.needUpdate == 0" @click="updateOpen = !updateOpen" style="width: 40px;"></div>
            <div class="update-icon" v-if="$store.getters.needUpdate == 1" @click="updateOpen = !updateOpen"></div>
            <div class="update-list" v-show="updateOpen" style="width: 150px; right: 10px;">
              <div class="update-item" v-if="$store.getters.needUpdate == 1"  @click="update">Update application</div>
              <div class="update-item" @click="updateContent">Update content</div>
            </div>
          </div>
          <div class="offline-icon" v-else></div>
          <div class="update-elegant" v-if="$store.getters.isOnline">
            <div class="update-item pointer" v-if="$store.getters.needUpdate == 1"  @click="update">Update application</div>
            <div class="update-elegant__text pointer" v-else @click="updateContent">
              <div title="UPDATE CONTENT" class="update-icon"/>
            </div>
          </div>
        </div>
            <ShowHome :class="{'hidden': this.$parent.activeTab !== 'home'}"></ShowHome>
            <Floors :isMounted=" this.$parent.activeTab === 'Floor plates'" :class="{'hidden': this.$parent.activeTab !== 'Floor plates'}" v-if="$store.getters.project.floors.length > 0"></Floors>
            <ShowUnits :class="{'hidden': this.$parent.activeTab !== 'Units'}"></ShowUnits>
            <Gallery v-if="$store.getters.project.galleries.length > 0" :class="{'hidden': this.$parent.activeTab !== 'Gallery'}" ></Gallery>
            <ShowMap v-if="this.$parent.activeTab === 'Contact'"></ShowMap>
    </div>
</template>
<style>
.update-elegant {
  display: none;
}
</style>
<script>
  import constants from '../Constants';
  import ShowHome from '@/components/show/ShowHome.vue'
  import Floors from '@/components/show/ShowFloors.vue'
  import Gallery from '@/components/show/ShowGallery.vue'
  import ShowMap from '@/components/show/ShowMap.vue'
  import ShowUnits from '@/components/show/ShowUnits.vue'
  export default {
    components: {
      ShowHome,Floors, Gallery, ShowMap, ShowUnits
    },
    name: 'show',
    data () {
      return {
        forced_key: 0,
        project: this.$store.getters.project,
        tabsName:[],
        updateOpen: false,
      }
    },
    methods: {
      update() {
        let project = document.location.href.split('show/');
        let data = {project: project[project.length - 1]};
        let obj = this;
        $.ajax({
          url: constants.BACKEND_URL + 'project/take-version',
          type: 'GET',
          data: data,
          dataType: 'json',
          success: function (respond, status, jqXHR) {
            if(respond.ok == 1) {
              window.location.reload();
            }
          },
          error: function (error) {
            obj.$store.dispatch('set_status', false)
            console.log(error);
          }
        })
      },
      updateContent() {
        let project = document.location.href.split('show/');
        let data = {project: project[project.length - 1]};
        let obj = this;
        $.ajax({
          url: constants.BACKEND_URL + 'project/take-version',
          type: 'GET',
          data: data,
          dataType: 'json',
          success: function (respond, status, jqXHR) {
            if(respond.ok == 1) {
              obj.$parent.$parent.forced_key = !obj.$parent.$parent.forced_key;
            }
          },
          error: function (error) {
            obj.$store.dispatch('set_status', false)
            console.log(error);
          }
        })
      },
      openBrownMenu() {
        $('#brown-hidden-menu').css({'left': '0'})
      },
      openUpdateList(evt) {
        var path = (evt.composedPath && evt.composedPath()) || evt.path;
        for(var i = 0; i < path.length; i++) {
          var dataId = $(path[i]).attr('data-id');
          if(dataId === 'update-container') {
            return;
          }
        }
        this.updateOpen = false;
      },
      actualize() {

      },
      actualizeMarkers(){
        $(document).ready(()=> {
         this.actualize();
        });
        window.onresize = () => {
         this.actualize();
        }
      },
      startShow() {
        if(this.$parent.tabs.floors === true) {
          this.$parent.activeTab = 'Floor plates';
          this.$router.push(`/show/${this.$store.getters.project.special_link}/floors`)
          this.actualizeMarkers();
        } else if(this.$parent.tabs.units === true){
          this.$router.push(`/show/${this.$store.getters.project.special_link}/units`)
          this.$parent.activeTab = 'Units';
        } else if(this.$parent.tabs.galleries === true){
          this.$router.push(`/show/${this.$store.getters.project.special_link}/gallery`)
          this.$parent.activeTab = 'Gallery';
        } else if(this.$parent.tabs.map === true){
          this.$router.push(`/show/${this.$store.getters.project.special_link}/map`)
          this.$parent.activeTab = 'Contact';
        }
      },
      changeTab() {
        switch (this.$router.currentRoute.params.tab) {
          case 'map':
            this.$parent.activeTab = 'Contact'
            break;
          case 'units':
            this.$parent.activeTab = 'Units'
            break;
          case 'floors':
            this.$parent.activeTab = 'Floor plates'
            this.actualizeMarkers();
            break;
          case 'gallery':
            this.$parent.activeTab = 'Gallery'
            break;
        }
      }
    },
    beforeMount() {
      if(this.$router.currentRoute.params.tab) {
        if(this.$store.getters.project.id) {
          this.changeTab();
        } else {
          setTimeout(() => {
            this.changeTab();
          }, 500)
        }
      }
    },
    mounted(){
      document.addEventListener('click', this.openUpdateList)
    },
  }
</script>

<style scoped>
.update-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23c2c1c1' width='30px' height='30px' viewBox='0 0 24 24' id='update-alt' class='icon glyph'%3E%3Cpath d='M12,3A9,9,0,0,0,6,5.32V3A1,1,0,0,0,4,3V8a1,1,0,0,0,.92,1H10a1,1,0,0,0,0-2H7.11A7,7,0,0,1,19,12a1,1,0,0,0,2,0A9,9,0,0,0,12,3Z'/%3E%3Cpath d='M19.08,15H14a1,1,0,0,0,0,2h2.89A7,7,0,0,1,5,12a1,1,0,0,0-2,0,9,9,0,0,0,15,6.68V21a1,1,0,0,0,2,0V16A1,1,0,0,0,19.08,15Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  width: 20px;
  height: 20px;
}
</style>
